<template>
  <layout>
    <template #secondarySidebar>
      <reports-nav />
    </template>

    <reports-toolbar
      v-model:filters="state.filters"
      title="Range Report"
      :machines="state.machines"
      @update-filters="updateFilters"
    />

    <!-- Content -->
    <div v-if="state.apiError" class="">
      <div v-if="isLoading" class="flex items-center mt-2">
        <icon-loading class="w-6 h-6 text-blumine mr-3" /> Loading data...
      </div>
      <div v-else>
        <alert-response-warning :error="state.apiError" />
        <!-- <div class="text-sm mt-2">Change filter or <button @click="reload" class="focus:outline-none">Try Again</button></div> -->
      </div>
    </div>
    <div v-else>
      <div class="flex flex-col space-y-4 lg:flex-row lg:space-x-4 xxl:space-x-6 lg:space-y-0 mt-6 xl:mt-8">
        <div class="w-full lg:w-1/2">
          <div class="flex items-center space-x-1.5 uppercase text-xs text-big-stone float-right font-semibold transform -translate-y-0.5">
            <div class="pb-0.5">Show Revenue</div>
            <toggle
              v-model="state.perDayChartToggle"
            />
          </div>
          <vends-by-machine
            v-if="!state.perDayChartToggle"
            v-model:zoom="state.perDayChartZoom"
            title="Vends by Machine"
            :is-loading="isLoading"
            :data="state.per_day_vends"
          />
          <revenue-by-machine
            v-else
            v-model:zoom="state.perDayChartZoom"
            title="Revenue by Machine"
            :is-loading="isLoading"
            :data="state.per_day_revenue"
          />
        </div>
        <div class="w-full lg:w-1/2">
          <avg-vends-and-avg-temp :is-loading="isLoading" :data="state.averages" />
        </div>
      </div>

      <div class="mt-3 md:mt-4 xxl:mt-6 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 xl:grid-cols-4 xxl:gap-8">
        <stat-card :is-loading="isLoading" color="bg-big-stone">
          <template #value>{{ state.totals.total_vends }}</template>
          <template #label>TOTAL PAID VENDS</template>
          <template #footer>
            ICE: {{ state.totals.ice_vends }}({{ percentages.iceVends }}%) | WATER: {{ state.totals.water_vends }}({{ percentages.waterVends }}%)
          </template>
        </stat-card>

        <stat-card :is-loading="isLoading" color="bg-blumine">
          <template #value>{{ state.totals.avg_day_vends }}</template>
          <template #label>AVG. DAY PAID VENDS</template>
          <template #footer>NATIONAL AVG.: {{ state.totals.avg_day_vends_national }}</template>
        </stat-card>

        <stat-card :is-loading="isLoading" color="bg-shakespear">
          <template #value>{{ money.format(state.totals.total_revenue) }}</template>
          <template #label>TOTAL REVENUE</template>
          <template #footer>
            ICE: {{ state.totals.ice_revenue }}({{ percentages.iceRevenue }}%) | WATER: {{ state.totals.water_revenue }}({{ percentages.waterRevenue }}%)
          </template>
        </stat-card>

        <stat-card :is-loading="isLoading" color="bg-gray-500">
          <template #value>{{ percentages.avgVendsCount }} %</template>
          <template #label>AVG. VEND COUNT CHANGE FROM PREVIOUS YEAR BY DATE</template>
          <template #footer>AVG. CHANGE BY DOW: {{ percentages.avgChangeDow }} %</template>
        </stat-card>
      </div>

      <div class="mt-4 xxl:mt-6">
        <stats-table
          :data="state.table"
          :is-loading="isLoading"
          :type="state.filters.type"
          :export-filter-desc="exportFilterDesc"
        />
      </div>
    </div>
  </layout>
</template>

<script>
import { computed, reactive, watch } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import http from '@/services/http.js'
import loading from '@/helpers/loading.js'
import date from '@/helpers/date.js'
import money from '@/helpers/money.js'

import Layout from '@/layouts/Default.vue'
import ReportsNav from '@/components/reports/Nav.vue'
import ReportsToolbar from '@/components/reports/Toolbar.vue'

import VendsByMachine from '@/components/reports/VendsByMachine.vue'
import RevenueByMachine from '@/components/reports/RevenueByMachine.vue'
import AvgVendsAndAvgTemp from '@/components/reports/AvgVendsAndAvgTemp.vue'
import StatCard from '@/components/reports/StatCard.vue'
import StatsTable from '@/components/reports/StatsTable.vue'
import Toggle from '@/components/form/Toggle.vue'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import { IconLoading } from '@/components/icons'


export default {
  name: 'ReportsRange',

  components: {
    Layout,
    ReportsNav,
    ReportsToolbar,
    VendsByMachine,
    RevenueByMachine,
    AvgVendsAndAvgTemp,
    StatCard,
    StatsTable,
    Toggle,
    IconLoading,
    AlertResponseWarning,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      apiError: false,
      perDayChartToggle: false,
      perDayChartZoom: 3,
      per_day_vends: [],
      per_day_revenues: [],
      averages: {},
      table: [],
      machines: [],
      totals: {},
      isOpen: false,
      filters: {
        type: 'all',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        machines: [],
        range: {
          from: date.now().subtract(1, 'day').subtract(1, 'week').format('YYYY-MM-DD'),
          to: date.now().format('YYYY-MM-DD'),
        }
      },
    })
    const machineIdsString = computed(() => state.filters.machines.join(','))
    const percentages = computed(() => ({
      iceRevenue: parseInt(state.totals.ice_revenue / state.totals.total_revenue * 100),
      waterRevenue: parseInt(state.totals.water_revenue / state.totals.total_revenue * 100),
      iceVends: parseInt(state.totals.ice_vends / state.totals.total_vends * 100),
      waterVends: parseInt(state.totals.water_vends / state.totals.total_vends * 100),
      avgVendsCount: state.totals.total_vends_previous === 0 ? 0 : parseInt((state.totals.total_vends - state.totals.total_vends_previous) / state.totals.total_vends_previous * 100.0),
      avgChangeDow: state.totals.total_vends_previous_dow === 0 ? 0 : parseInt((state.totals.total_vends - state.totals.total_vends_previous_dow) / state.totals.total_vends_previous_dow * 100.0),
    }))

    const hydrateFiltersFromUrl = () => {
      state.filters.machines = route.query.machines ? route.query.machines.split(',').map((m) => parseInt(m)) : []
      route.query.from ? state.filters.range.from = route.query.from : null
      route.query.to ? state.filters.range.to = route.query.to : null
      route.query.timezone ? state.filters.timezone = route.query.timezone : null
      route.query.type ? state.filters.type = route.query.type : null
      state.selectedMachines = state.machines.filter((machine) => state.filters.machines.includes(machine.location_id))
    }

    const updateFilters = () => {
      router.replace({
        query: {
          from: state.filters.range.from,
          to: state.filters.range.to,
          timezone: state.filters.timezone,
          machines: machineIdsString.value,
          type: state.filters.type,
        }
      })
    }

    const getMachines = async () => {
      hydrateFiltersFromUrl()

      const { data: machines } = await http.get('user/machines')
      const allowedLocations = machines.allowed_locations.filter(m => !m.is_technician)
      state.machines = state.selectedMachines = allowedLocations

      if (allowedLocations.length === 0) {
        loading.end()
        state.apiError = 'You either do not have any machines assigned or you are now allowed to view reports on any of the assigned machines.'
      }
      else if (state.filters.machines.length === 0) {
        state.filters.machines = allowedLocations.map((machine) => machine.location_id)
        updateFilters()
      }
      else {
        getData()
      }
      state.selectedMachines = state.machines.filter((machine) => state.filters.machines.includes(machine.location_id))
    }

    const pollRangeData = async (guid, isRecursive = false) => {
      if (isRecursive) {
        await new Promise((resolve) => setTimeout(() => resolve(), 1000))
      }

      try {
        const { data } = await http.get(`/task/result/${guid}`)

        if (data.error) {
          state.apiError = data.error
        }
        if (data.data) {
          return data.data
        }

        return await pollRangeData(guid, true)
      } catch (e) {
        if (e.response) {
          e.response.data.Message ? state.apiError = e.response.data.Message : state.apiError = 'Server Error'
        } else {
          state.apiError = 'Request failed'
        }
      }
    }

    const getData = async () => {
      loading.start()

      try {
        const { data } = await http.post('task/request', {
          task_type: 'report_range',
          data: {
            location_ids: state.filters.machines,
            start: state.filters.range.from,
            end: state.filters.range.to,
            timezone: state.filters.timezone,
            type: state.filters.type
          }
        })

        const [rangeData, { data: weather }] = await Promise.all([
          pollRangeData(data.guid),
          http.get('report/weather', {
            params: {
              location_ids: machineIdsString.value,
              start: state.filters.range.from,
              end: state.filters.range.to,
              timezone: state.filters.timezone,
              type: state.filters.type
            }
          }),
        ])

        state.apiError = false
        state.per_day_vends = rangeData.per_day_vends
        state.per_day_revenue = rangeData.per_day_revenue
        state.averages = {
          per_day_weather: weather.per_day_weather,
          vends: rangeData.averages
        }
        state.table = rangeData.table
        state.totals = rangeData.totals
        weather.per_location_weather.forEach((weather) => {
          const table = state.table.find((t) => t.location_id === weather.location_id)
          table.temp = weather.temp
        })
      } catch (e) {
        if (e.response) {
          e.response.data.Message ? state.apiError = e.response.data.Message : state.apiError = 'Server Error'
        } else {
          state.apiError = 'Request failed'
        }
      }

      loading.end()
    }

    const exportFilterDesc = computed(() => {
      let value = ''
      if (state.filters.machines.length > 0) {
        const machineNames = state.filters.machines.map(machine => {
          return state.machines.find(m => m.location_id === machine)?.name
        })
        value += `Machines: ${machineNames.join(', ')}\n`
      }
      if (state.filters.type) {
        value += `Type: ${state.filters.type}\n`
      }
      if (state.filters.range) {
        value += `Date Range: ${state.filters.range.from} - ${state.filters.range.to}`
      }
      if (value.length > 0) value = 'Active Filter\n' + value
      return value
    })

    watch(
      () => route.query,
      () => {
        if (route.name === 'ReportsRange') {
          // Set timeout to get correct query params from URL
          setTimeout(() => {
            hydrateFiltersFromUrl()
            getData()
          }, 100)
        }
      }
    )

    onBeforeRouteUpdate(() => {
      setTimeout(() => {
        if (Object.keys(route.query).length === 0 && route.query.constructor === Object) {
          updateFilters()
        }
      }, 100)
    })

    loading.start()
    getMachines()

    return {
      state,
      isLoading: loading.status,
      money,
      getData,
      updateFilters,
      percentages,
      exportFilterDesc,
      reload: () => location.reload(),
    }
  }
}
</script>
