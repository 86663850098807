<template>
  <chart-card :is-loading="isLoading" title="Average Vends and Average Temperature">
    <div class="px-2 py-4 relative">
      <zoom-nav @zoom="handleZoom" :min="0" :max="3" class="absolute right-0 top-0 m-4 flex shadow-lg" />
      <chart type="bar" :labels="values.labels" :data="values.values" :options="{
        scales: {
          xAxes: [{
            offset: true,
          }]
        }
      }" />
    </div>
  </chart-card>
</template>

<script>
import { computed, ref } from 'vue'
import dateLib from '@/helpers/date.js'
import ChartCard from '@/components/reports/ChartCard.vue'
import Chart from '@/components/charts/Chart.vue'
import ZoomNav from '@/components/charts/ZoomNav.vue'

export default {
  name: 'reports-avg-vends-and-avg-temp',

  components: {
    Chart,
    ChartCard,
    ZoomNav,
  },

  props: ['data', 'range', 'machines', 'isLoading'],

  setup(props) {
    const groupBy = ref('days')

    const groupByType = (type, data, keys, finalCb) => {
      let grouped = []
      let format = 'MMM D'

      data = JSON.parse(JSON.stringify(data))

      if (groupBy.value === 'days') return {
        labels: data.map((item) => dateLib.instance(item.date).format(format)),
        data,
      }

      data.forEach((item) => {
        const [year, month] = item.date.split('-')
        let date = null

        if (type === 'months') {
          date = `${year}-${month}`
          format = 'YYYY MMM'
        } else if (type === 'weeks') {
          date = `${year}-${dateLib.instance(item.date).week()}`
          format = null
        } else {
          date = year
          format = 'YYYY'
        }

        const index = grouped.findIndex((data) => data.date === date)

        if (index === -1) {
          const obj = {}
          keys.forEach((key) => {
            obj[key] = [item[key]]
          })
          grouped.push({
            date,
            ...obj,
          })
        } else {
          keys.forEach((key) => {
            grouped[index][key].push(item[key])
          })
        }
      })

      return {
        labels: grouped.map((item) => format ? dateLib.instance(item.date).format(format) : item.date),
        data: grouped.map(finalCb)
      }
    }

    const values = computed(() => {
      if (!props.data) return {}

      const vends = groupByType(
        groupBy.value,
        JSON.parse(JSON.stringify(props.data.vends)),
        ['national_average', 'selected_average'],
        (item) => ({
          date: item.date,
          national_average: Math.round(item.national_average.reduce((sum, item) => sum + item, 0) / item.national_average.length),
          selected_average: Math.round(item.selected_average.reduce((sum, item) => sum + item, 0) / item.selected_average.length),
        })
      )

      const weather = groupByType(
        groupBy.value,
        JSON.parse(JSON.stringify(props.data.per_day_weather)),
        ['temp'],
        (item) => ({
          date: item.date,
          temp: Math.round(item.temp.reduce((sum, item) => sum + item, 0) / item.temp.length),
        })
      )

      return {
        labels: vends.labels,
        values: [
          {
            label: 'Average temperature',
            data: weather.data.map((item) => item.temp),
            borderColor: '#fed518',
            backgroundColor: '#fed518',
            pointRadius: 0,
            type: 'line',
            fill: false,
          },
          {
            label: 'Average vends per day',
            data: vends.data.map((item) => item.selected_average),
            backgroundColor: '#182D3F',
            borderColor: '#182D3F',
          }, {
            label: 'National avg. per day',
            data: vends.data.map((item) => item.national_average),
            backgroundColor: '#6997BF',
            borderColor: '#6997BF',
          }
        ]
      }
    })

    const handleZoom = (index) => {
      groupBy.value = {
        0: 'years',
        1: 'months',
        2: 'weeks',
        3: 'days',
      }[index]
    }

    return {
      values,
      groupBy,
      handleZoom,
    }
  }
}
</script>
