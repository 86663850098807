<template>
  <div v-if="isLoading" class="p-4 bg-white flex items-center rounded-md shadow-sm">
    <loading class="text-big-stone w-5 h-5 mr-3" />
    Loading data...
  </div>

  <div v-else>
    <datatables-table
      id="datatables-range-reports"
      :columns="columns"
      :sort-by="{
        key: 'location_name',
        direction: 'ASC'
      }"
      show-footer
    >
      <template #footer>
        <div class="flex flex-col md:flex-row md:justify-between md:items-start mt-4">
          <action-buttons
            :data="dataForExport"
            :filter="exportFilterDesc"
            filename="RangeReport"
            class="py-0.5"
          />
        </div>
      </template>
    </datatables-table>
  </div>
</template>

<script>
import { computed } from 'vue'
import { avg, sum, totalExportRow } from '@/helpers/datatables.js'
import Loading from '@/components/icons/Loading.vue'
import DatatablesTable from '@/components/datatables/Table.vue'
import MoneyField from '@/components/datatables/Fields/MoneyField.vue'
import TemperatureField from '@/components/datatables/Fields/TemperatureField.vue'
import PercentageWithColorsField from '@/components/datatables/Fields/PercentageWithColorsField.vue'
import ActionButtons from '@/components/datatables/ActionButtons.vue'

export default {
  name: 'ReportsStatsTable',

  components: {
    Loading,
    DatatablesTable,
    ActionButtons,
  },

  props: ['data', 'isLoading', 'type', 'exportFilterDesc'],

  setup(props) {
    const columns = computed(() => {
      return props.data.map((item) => ({
        className: '',
        columns: [
          { title: 'Machine', key: 'location_name', value: item.location_name, className: 'font-bold text-left text-base', priority: 1, footer: 'Total' },
          { type: 'ice', title: 'Paid Vends', key: 'total_vends', value: item.total_vends, className: 'text-right', priority: 2, footer: sum },
          { type: 'ice', title: 'Avg. Vends', key: 'average_vends', value: item.average_vends, className: 'text-right', priority: 3, footer: avg },
          { type: 'ice', title: 'Bag', key: 'bag', value: item.bag, className: 'text-right', priority: 4, footer: sum },
          { type: 'ice', title: 'Bulk', key: 'bulk', value: item.bulk, className: 'text-right', priority: 5, footer: sum },
          { type: 'ice', title: 'Free', key: 'free', value: item.free, className: 'text-right', priority: 6, footer: sum },
          { type: 'ice', title: 'I. Revenue', key: 'ice_revenue', value: item.ice_revenue, className: 'text-right', priority: 7, component: MoneyField, footer: sum },
          { type: 'water', title: 'Gal 1', key: 'gal1', value: item.gal1, className: 'text-right', priority: 7, footer: sum },
          { type: 'water', title: 'Gal 3', key: 'gal3', value: item.gal3, className: 'text-right', priority: 7, footer: sum },
          { type: 'water', title: 'Gal 5', key: 'gal5', value: item.gal5, className: 'text-right', priority: 7, footer: sum },
          { type: 'water', title: 'Avg. Gals', key: 'average_gals', value: item.average_gals, className: 'text-right', priority: 7, footer: avg },
          { type: 'water', title: 'W. Revenue', key: 'water_revenue', value: item.water_revenue, className: 'text-right', priority: 7, component: MoneyField, footer: sum  },
          { title: 'Day %', key: 'day_percentage', value: item.total_vends_previous === 0 ? 0 : (item.total_vends - item.total_vends_previous) / item.total_vends_previous * 100.0, className: 'text-right', priority: 7, component: PercentageWithColorsField, footer: avg },
          { title: 'Dow %', key: 'dow_percentage', value: item.total_vends_previous_dow === 0 ? 0 : (item.total_vends - item.total_vends_previous_dow) / item.total_vends_previous_dow * 100.0, className: 'text-right', priority: 7, component: PercentageWithColorsField, footer: avg },
          { type: 'all', title: 'T. Revenue', key: 'total_revenue', value: item.total_revenue, className: 'text-right', priority: 4, component: MoneyField, footer: sum },
          { title: 'Avg. Temp', key: 'temp', value: item.temp, className: 'text-right', priority: 4, component: TemperatureField, footer: avg },
        ].filter((column) => props.type === 'all' || column.type === props.type || column.type === undefined)
      }))
    })

    const dataForExport = computed(() => {
      const tableData = columns.value.map((row) => row.columns)
      const totalRow = totalExportRow(columns.value)
      if (totalRow) {
        tableData.push(totalRow)
      }
      return tableData
    })

    return {
      columns,
      dataForExport,
    }
  }
}
</script>
