<template>
  <div class="h-full">
    <div :class="`flex flex-col justify-between h-full text-white ${color} overflow-hidden rounded-md shadow-sm` ">
      <div class="text-center p-4 md:py-8 flex flex-col justify-center items-center h-full">
        <icon-loading v-if="isLoading" class="w-5 h-5" />
        <div v-else>
          <div class="font-black text-xl sm:text-2xl lg:text-3xl xl:text-4xl xxl:text-5xl">
            <slot name="value" />
          </div>
          <div class="text-sm lg:text-base">
            <slot name="label" />
          </div>
        </div>
      </div>
      <div v-if="!isLoading" class="border-t text-sm text-center p-2 md:py-4">
        <div class="font-semibold">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconLoading } from '@/components/icons'

export default {
  name: 'stat-card',

  components: {
    IconLoading,
  },

  props: ['isLoading', 'value', 'label', 'color'],
}
</script>
