<template>
  <chart-card :is-loading="isLoading" :title="title">
    <div class="px-2 py-4 relative">
      <zoom-nav
        @zoom="handleZoom"
        :min="0"
        :max="3"
        :default-zoom="zoom"
        class="absolute right-0 top-0 m-4 flex shadow-lg"
      />
      <chart
        type="line"
        :labels="values.labels"
        :data="values.values"
      />
    </div>
  </chart-card>
</template>

<script>
import { computed, ref } from 'vue'
import dateLib from '@/helpers/date.js'
import Chart from '@/components/charts/Chart.vue'
import ChartCard from '@/components/reports/ChartCard.vue'
import ZoomNav from '@/components/charts/ZoomNav.vue'

export default {
  name: 'reports-vends-by-machine',

  components: {
    Chart,
    ChartCard,
    ZoomNav,
  },

  props: ['title', 'data', 'isLoading', 'zoom'],

  setup(props, { emit }) {
    let groupBy = ref('days')

    const machines = computed(() => {
      if (! props.data[0]) {
        return []
      }

      return props.data[0].items
    })

    const groupByType = (type, data) => {
      let grouped = []
      let format = 'MMM D'

      data = JSON.parse(JSON.stringify(data))

      if (groupBy.value === 'days') return {
        labels: data.map((item) => dateLib.instance(item.date).format(format)),
        data,
      }

      data.forEach((item) => {
        const [year, month] = item.date.split('-')
        let date = null

        if (type === 'months') {
          date = `${year}-${month}`
          format = 'YYYY MMM'
        } else if (type === 'weeks') {
          date = `${year}-${dateLib.instance(item.date).week()}`
          format = null
        } else {
          date = year
          format = 'YYYY'
        }

        const index = grouped.findIndex((data) => data.date === date)

        if (index === -1) {
          grouped.push({
            date: date,
            items: item.items,
          })
        } else {
          grouped[index].items.forEach((previous) => {
            const actual = item.items.find((m) => m.location_id === previous.location_id)
            previous.value += actual.value
          })
        }
      })

      return {
        labels: grouped.map((item) => format ? dateLib.instance(item.date).format(format) : item.date),
        data: grouped,
      }
    }

    const values = computed(() => {
      if (!props.data) return []

      const { data, labels } = groupByType(groupBy.value, props.data)

      return {
        labels: labels,
        values: machines.value.map(
          (machine) => ({
            label: machine.name,
            data: JSON.parse(JSON.stringify(data)).map((item) => item.items.find((item) => item.location_id === machine.location_id).value),
            fill: false,
          })
        )
      }
    })

    const handleZoom = (index) => {
      groupBy.value = {
        0: 'years',
        1: 'months',
        2: 'weeks',
        3: 'days',
      }[index]
      emit('update:zoom', index)
    }

    if (props.zoom >= 0) handleZoom(props.zoom)

    return {
      values,
      groupBy,
      handleZoom,
    }
  }
}
</script>
